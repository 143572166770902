<template lang="pug">

div
  v-btn(text color="orange" @click="$router.go(-1)")
      v-icon(left) keyboard_backspace
      | Back
  v-card
    v-card-title(primary-title)
      div
        h3.headline.mb-0 {{model.make}} - {{model.name}}
        br
        div
          span {{model.description}}
        br
        v-chip(v-if="!model.pictures") Needs Pictures
    v-card-actions
      v-btn(text color="orange" v-clipboard:copy="model.description" @click="snackbar = true")
        v-icon(left) file_copy
        | Copy
      v-btn(text color="red" @click="deleteModel(model)")
        v-icon(left) delete_sweep
        | Delete
  br
  v-card
    v-card-title
      form
        v-autocomplete(v-model="model.make" :items="makes" item-value="name" item-text="name" label="Make")
        v-text-field(v-model="model.name" label="Name" type="text")
        v-checkbox(label="Has Pictures?" v-model="model.pictures")
        v-textarea(name="input-7-1" label="Description" v-model="model.description")
    v-card-actions
      v-btn(color="primary" @click="saveEdit") Save Edit
  v-snackbar(v-model="snackbar" right top) Description copied to clipboard.
    v-btn(color="pink" text @click="snackbar = false") Close
  v-snackbar(v-model="snackbarsaved" right top) Edits Saved
    v-btn(color="pink" text @click="snackbarsaved = false") Close
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  data () {
    return {
      snackbar: false,
      snackbarsaved: false
    }
  },
  methods: {
    deleteModel (model) {
      const lastchance = confirm('Are you sure you want to delete the model ' + model.name + '? This cannot be undone.')
      if (lastchance) {
        this.$store.dispatch('deleteModel', model.id)
        this.$router.go(-1)
      }
    },
    saveEdit () {
      const newModel = {
        make: this.model.make || '',
        name: this.model.name || '',
        pictures: this.model.pictures || false,
        description: this.model.description || '',
        id: this.model.id
      }
      this.snackbarsaved = true
      this.$store.dispatch('updateModel', newModel)
    }
  },
  computed: {
    ...mapGetters([
      'makes'
    ]),
    model () {
      return this.$store.getters.getModelById(this.$route.params.id)
    }
  }
}
</script>
<style lang="stylus" scoped>

span
  white-space pre

</style>
